<template>
  <div class="cancel-subscription">
    <h1 class="cancel-subscription__title">Отписаться от СМС-рассылки</h1>
    <Checkbox
      :label="`Я не хочу получать СМС-рассылку`"
      :type="'checkbox'"
      class="mt-9"
      v-model="cancel"
    />
    <div
      class="mt-9 cancel-subscription__btn d-md-inline-flex button button_pink"
    >
      Отписаться
    </div>
  </div>
</template>

<script>
import Checkbox from "../components/form-elements/Checkbox.vue";
export default {
  metaInfo: {
    title: "Отписаться от рассылки",
  },
  name: "CancelSubscription",
  components: {
    Checkbox,
  },
  data: () => ({
    cancel: false,
  }),
};
</script>

<style lang="scss" scoped>
.cancel-subscription {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 29px;
    }
  }
}
</style>